import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import styles from './styles.module.scss';
import { IStatEntity } from '@/features/home/domain';

type StatSlideProps = {
  items: IStatEntity[];
};

export const StatSlide: FC<StatSlideProps> = ({ items }) => {
  return (
    <Box className={styles.slide}>
      {items.map((number) => (
        <Box key={number.id} className={styles.slideItem}>
          <Box className={styles.slideItemTitleWrapper}>
            <Typography variant="h3" className={styles.slideItemTitle}>
              {`${number.title.startSymbol ?? ''}${number.title.number}`}
              <span className="pw-text-gradient">{number.title.endSymbol}</span>
            </Typography>
          </Box>
          <Typography variant="subtitle1" className={styles.slideItemDescription}>
            {number.description}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
